
const columns = [
  {
    title: 'File name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Image',
    dataIndex: 'mediaUrl',
    key: 'mediaUrl',
  },
];

import { computed, defineComponent, ref } from 'vue';

import type { UploadChangeParam, UploadFile } from 'ant-design-vue';

import MediaCards from '@/components/cards/MediaCards.vue';
import TableList from '@/components/tables/TableList.vue';
import axios from '@/utils/axios';

export default defineComponent({
  name: 'UploadImage',
  components: {
    MediaCards,
    TableList,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const fileLength = ref(0);
    const mediaCardRef = ref();
    const tableListRef = ref();
    const isLoading = ref(false);

    const onChangeImage = (info?: UploadChangeParam<UploadFile<unknown>>) => {
      console.log(info);
      fileLength.value = info?.fileList.length || 0;
      // fileList.value = info.fileList;
    };

    const onSelectImage = (info?: UploadChangeParam<UploadFile<unknown>>) => {
      fileLength.value = info?.fileList.length || 0;
    };

    const onClick = async () => {
      // console.log('onClick');
      isLoading.value = true;
      const { api, image, params } = await mediaCardRef.value.submit();
      console.log(api, image, params);
      const getNewData = () => {
        switch (api) {
          case 'image-results/virtual-skin-simples':
            return {
              ...params,
              faceId1: image[0].faces[0].id,
              faceId2: image[1].faces[0].id,
              name: image[0].uid,
            };
          case 'image-results/virtual-skins':
            return {
              ...params,
              faceIds: image.map(
                (obj: { faces: { id: any }[] }) => obj.faces[0].id
              ),
              name: image[0].uid,
            };
          default:
            return {
              ...params,
              faceId1: image[0].faces[0].id,
              faceId2: image[1].faces[0].id,
              name: image[0].uid,
            };
        }
      };

      const uploadData = getNewData();

      axios
        .post(api, uploadData)
        .then((res) => {
          tableListRef.value.getApiData();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const getDisableSubmit = computed(
      () => mediaCardRef.value?.getDisableSubmit
    );

    return {
      columns,
      // state
      isLoading,
      fileLength,
      mediaCardRef,
      tableListRef,
      // func
      onChangeImage,
      onSelectImage,
      onClick,

      // computed
      getDisableSubmit,
    };
  },
});
